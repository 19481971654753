//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';

// modal override
.modal-content {
  padding: 20px;
}

.modal-dialog {
  margin-top: 100px;
}

.modal-close {
  text-align: right;
}

.modal-close-icon {
  font-size: 25px;
  cursor: pointer;
}

.modal-button {
  margin-top: 20px;
  text-align: center;
}

// sql
.sql-label {
  font-weight: bold;
}

.sql-content {
  font-size: 15px;
}

// custom
.c-tar {
  text-align: right;
}

.c-tac {
  text-align: center;
}

.c-tal {
  text-align: left;
}

.c-cp {
  cursor: pointer;
}

// font size
.c-fs10 {
  font-size: 10px;
}

.c-fs15 {
  font-size: 15px;
}

.c-fs20 {
  font-size: 20px;
}

// spacing override
.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-4 {
  margin-left: 16px;
}

.mr-5 {
  margin-left: 20px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-5 {
  margin-right: 20px;
}

// font style
.c-bold {
  font-weight: bold;
}

// color
.c-blue {
  color: #009ef7 !important;
}

.c-yellow {
  color: #ffc700;
}

// width
.c-w100p {
  width: 100% !important;
}

// height
.c-h100 {
  height: 100px;
}

// security-grade
.security-grade-icon {
  font-size: 50px;
}

.security-grade-step {
  font-size: 20px;
  color: '#009ef7';
}

.security-grade-title {
  font-size: 15px;
}

.security-grade-content {
  font-size: 15px;
  height: 50px;
}

.security-grade-check {
  font-size: 40px;
  color: #50cd89;
}

// deposit and withdrawal
.daw-symbol {
  width: 40px;
  height: 40px;
}

.daw-gray {
  color: #b1b1b1;
}

.daw-active {
  background-color: #009ef7;
}

.daw-account {
  text-align: center;
  border: 1px solid $secondary;
  padding: 20px;
  font-size: 20px;
}

// custom
.stackedit__html {
  margin-bottom: 0px !important;
}
